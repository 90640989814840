import useMediaQuery from "@mui/material/useMediaQuery";
import _ from "lodash";
import { useRouter } from "next/router";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkLightCastTokenTimeOut } from "../../../helpers/auth";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import {
  updateJobAlertCreateDialog,
  updateJobAlertCreateDialogWithKeyword,
  updateJobAlertsDownloadAppDialog,
} from "../../../redux/actions/dialog_action";
import {
  createJobAlert,
  getJobAlerts,
} from "../../../redux/actions/job_action";
import {
  fetchingJobList,
  updateJobTitleSuggestionsDropdownStatus,
} from "../../../redux/actions/user_action";
import * as types from "../../../redux/types/job_type";
import Button from "../../shared/SharedButton/SharedButton";
import {
  ActionsContainer,
  ButtonContainer,
  ButtonGroupStyled,
  CenterButton,
  CloseButtonContainer,
  CloseIconButton,
  CloseIconStyled,
  ContainerStyled,
  DialogContentStyled,
  DialogStyled,
  DropdownItem,
  DropdownListContainer,
  DropdownText,
  FormControlLabelStyled,
  FormControlStyled,
  LabelContainer,
  LabelStyled,
  LeftButton,
  RadioGroupStyled,
  RightButton,
  SingleRadioStyled,
  SparklesStyled,
  SubtitleStyled,
  TextFieldStyled,
  TitleStyled,
} from "./styles";

function JobAlertsCreateDialog(props) {
  const { selectedCountry } = props;
  const dispatch = useDispatch();

  const currentCountry = process?.env?.NEXT_PUBLIC_JSW_GEOLOCATION;

  const [keyword, setKeyword] = useState("");
  const [selectedFrequency, setSelectedFrequency] = useState("daily");
  const [selectedMedium, setSelectedMedium] = useState("all");

  const showJobAlertCreateDialog = useSelector(
    (state) => state.dialog.showJobAlertCreateDialog
  );
  const jobTitleDropdownStatus = useSelector(
    (state) => state.user.jobTitleDropdownStatus
  );
  const jobAlertTitles = useSelector(
    (state) => state.user?.onboardingJobTitles
  );

  const [triggerSnackbarFunc] = useTriggerSnackbar();
  const router = useRouter();

  const isTabletScreen = useMediaQuery("(min-width:768px)");

  const searchKeyword = useSelector(
    (state) => state.dialog?.logInJobAlertDialogInfo?.keyword
  );

  const searchKeywordOrigin = useSelector(
    (state) => state.dialog?.jobAlertKeywordOrigin
  );

  const handleClose = () => {
    dispatch(updateJobAlertCreateDialog(false));
    setKeyword("");
    setSelectedFrequency("daily");
    setSelectedMedium("all");
  };

  useEffect(() => {
    checkLightCastTokenTimeOut();
  }, []);

  // Fetching job from lightcast
  const fetchJob = _.debounce((value) => {
    dispatch(fetchingJobList(value));
  }, 300);

  const debouncedFetchJob = useCallback((value) => fetchJob(value), []);
  // Done fetching job from lightcast

  const onKeywordUpdate = (event) => {
    setKeyword(event.target?.value);

    dispatch(
      updateJobAlertCreateDialogWithKeyword({
        keyword: event.target.value,
      })
    );

    if (keyword.length >= 3 && !jobTitleDropdownStatus) {
      dispatch(updateJobTitleSuggestionsDropdownStatus(true));
    }

    if (keyword) {
      debouncedFetchJob(event.target?.value);
    }
  };

  const handleButtonClicked = (type, value) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (type == "frequency") {
      setSelectedFrequency(value);
    } else {
      setSelectedMedium(value);

      if (value == "notification" || value == "all") {
        dispatch(updateJobAlertsDownloadAppDialog(true));
      }
    }
  };

  const handleCreateAlert = () => {
    dispatch(getJobAlerts());
    if (
      !searchKeyword ||
      (searchKeyword &&
        typeof searchKeyword === "string" &&
        searchKeyword?.trim()?.length == 0)
    ) {
      triggerSnackbarFunc({
        snackbarMessage: "Please enter a keyword to create job alert.",
        severity: "error",
      });
      return;
    }

    let params = {
      title: searchKeyword,
      frequency: selectedFrequency,
      medium: selectedMedium,
      useFullUrl: true,
      country: selectedCountry ? selectedCountry : currentCountry,
    };

    dispatch(createJobAlert(params)).then((response) => {
      if (response.type === types.CREATE_JOB_ALERT_SUCCEED) {
        // Custom events for GTM tracking
        dispatch(getJobAlerts());
        if (searchKeywordOrigin == "user-profile") {
          sendTrackingEvent({
            event: "CE_create-job-alert",
            origin: "profile",
            "job-alert-id": response?.jobAlert?.id,
            "job-alert-frequency": response?.jobAlert?.frequency,
            "job-alert-medium": response?.jobAlert?.medium,
            keyword: searchKeyword,
          });
        } else if (searchKeywordOrigin == "search-suggestion") {
          sendTrackingEvent({
            event: "CE_create-job-alert",
            origin: "recent_search",
            "job-alert-id": response?.jobAlert?.id,
            "job-alert-frequency": response?.jobAlert?.frequency,
            "job-alert-medium": response?.jobAlert?.medium,
            keyword: searchKeyword,
          });
        } else if (searchKeywordOrigin == "job-detail") {
          sendTrackingEvent({
            event: "CE_create-job-alert",
            origin: "job_detail",
            "job-alert-id": response?.jobAlert?.id,
            "job-alert-frequency": response?.jobAlert?.frequency,
            "job-alert-medium": response?.jobAlert?.medium,
            keyword: searchKeyword,
          });
        } else if (searchKeywordOrigin == "job-alert-banner") {
          sendTrackingEvent({
            event: "CE_create-job-alert",
            origin: "job_list",
            "job-alert-id": response?.jobAlert?.id,
            "job-alert-frequency": response?.jobAlert?.frequency,
            "job-alert-medium": response?.jobAlert?.medium,
            keyword: searchKeyword,
          });
        } else if (searchKeywordOrigin == "single-job-page") {
          sendTrackingEvent({
            event: "CE_create-job-alert",
            origin: "individual_job_page",
            "job-alert-id": response?.jobAlert?.id,
            "job-alert-frequency": response?.jobAlert?.frequency,
            "job-alert-medium": response?.jobAlert?.medium,
            keyword: searchKeyword,
          });
        }
        if (router.pathname.includes("/jobs")) {
          triggerSnackbarFunc({
            snackbarMessage: "Job alert created.",
            severity: "success",
            boolShowAction: true,
          });
        } else {
          triggerSnackbarFunc({
            snackbarMessage: "Job alert created.",
            severity: "success",
          });
        }

        handleClose();
      } else if (response.type === types.JOB_ALERT_EXISTS) {
        triggerSnackbarFunc({
          snackbarMessage: "Job alert already exists.",
          severity: "error",
        });
      } else {
        triggerSnackbarFunc({
          snackbarMessage: "Job alert cannot be created.",
          severity: "error",
        });
      }
    });
  };

  const onSelectJobTitle = (suggestedJobTitle) => {
    dispatch(
      updateJobAlertCreateDialogWithKeyword({
        keyword: suggestedJobTitle,
      })
    );
    dispatch(updateJobTitleSuggestionsDropdownStatus(false));
  };

  return (
    <DialogStyled onClose={handleClose} open={showJobAlertCreateDialog}>
      <CloseButtonContainer>
        <CloseIconButton onClick={handleClose}>
          <CloseIconStyled />
        </CloseIconButton>
      </CloseButtonContainer>
      <DialogContentStyled>
        <TitleStyled>Create New Job Alert</TitleStyled>
        <SubtitleStyled>
          Never miss out, be the first to receive matches!
        </SubtitleStyled>
        <ActionsContainer>
          <LabelContainer>
            <LabelStyled>Keyword</LabelStyled>
            <TextFieldStyled
              placeholder="E.g. Data Analyst"
              InputProps={{ disableUnderline: true }}
              onChange={onKeywordUpdate}
              value={searchKeyword}
            />
            {keyword.length >= 3 && jobTitleDropdownStatus ? (
              <DropdownListContainer>
                {jobAlertTitles.slice(0, 5).map((item, index) => {
                  return (
                    <DropdownItem
                      key={`${item}-${index}`}
                      onClick={() => onSelectJobTitle(item)}
                    >
                      <DropdownText className="dropdown">{item}</DropdownText>
                    </DropdownItem>
                  );
                })}
              </DropdownListContainer>
            ) : null}
          </LabelContainer>

          <LabelContainer>
            <LabelStyled>Select Frequency</LabelStyled>
            <ButtonGroupStyled>
              <LeftButton
                $selected={selectedFrequency == "daily"}
                onClick={handleButtonClicked("frequency", "daily")}
              >
                Daily
              </LeftButton>
              <RightButton
                $selected={selectedFrequency == "weekly"}
                onClick={handleButtonClicked("frequency", "weekly")}
              >
                Weekly
              </RightButton>
            </ButtonGroupStyled>
          </LabelContainer>

          <LabelContainer $jobAlertTip container columnSpacing={1}>
            <LabelStyled $jobAlertTip>
              <SparklesStyled />
              <ContainerStyled>
                {selectedFrequency == "daily"
                  ? "Job alert will be sent daily when new jobs are available."
                  : "Job alert will be sent every Monday when new jobs are available."}
              </ContainerStyled>
            </LabelStyled>
          </LabelContainer>

          <LabelContainer>
            <LabelStyled>Alert via</LabelStyled>
            <ButtonGroupStyled $alertVia>
              {isTabletScreen ? (
                <Fragment>
                  <LeftButton
                    $selected={selectedMedium == "all"}
                    onClick={handleButtonClicked("channel", "all")}
                    $compact_line_height
                  >
                    Email & <br /> Push Notification
                  </LeftButton>
                  <CenterButton
                    $selected={selectedMedium == "notification"}
                    onClick={handleButtonClicked("channel", "notification")}
                  >
                    Push Notification
                  </CenterButton>
                  <RightButton
                    $selected={selectedMedium == "email"}
                    onClick={handleButtonClicked("channel", "email")}
                  >
                    Email
                  </RightButton>
                </Fragment>
              ) : (
                <FormControlStyled>
                  <RadioGroupStyled
                    defaultValue={"all"}
                    onChange={(event) =>
                      handleButtonClicked("channel", event?.target?.value)
                    }
                  >
                    <FormControlLabelStyled
                      value="all"
                      control={<SingleRadioStyled />}
                      label={`Email & Push Notification`}
                    />
                    <FormControlLabelStyled
                      value="notification"
                      control={<SingleRadioStyled />}
                      label="Push Notification"
                    />
                    <FormControlLabelStyled
                      value="email"
                      control={<SingleRadioStyled />}
                      label="Email"
                    />
                  </RadioGroupStyled>
                </FormControlStyled>
              )}
            </ButtonGroupStyled>
          </LabelContainer>
        </ActionsContainer>
        <ButtonContainer>
          <Button
            onClick={handleCreateAlert}
            button_type={"Green"}
            desktop_width={"250px"}
            border_radius={"10px"}
          >
            Create Alert
          </Button>
        </ButtonContainer>
      </DialogContentStyled>
    </DialogStyled>
  );
}

export default JobAlertsCreateDialog;
