import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Country, isPossiblePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import * as constants from "../../../helpers/constant";
import { getJobDiscovery } from "../../../helpers/posthog_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useJobApplication from "../../../hooks/useJobApplication";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import {
  updateNationalityDialog,
  updateUserMobileNumberAndNationality,
} from "../../../redux/actions/user_action";
import * as types from "../../../redux/types/user_type";
import {
  AutocompleteStyled,
  ButtonBox,
  DialogStyled,
  DividerStyled,
  ErrorText,
  IconButtonStyled,
  PhoneInputStyled,
  SubmitButton,
  Subtitle,
  SubtitleBox,
  TextFieldStyled,
  Title,
  TitleContainer,
  XMarkIconStyled,
} from "./styles";

type OptionType = {
  state: string;
};

function SharedNationalityDialog(props: any) {
  const { jobOrigin } = props;

  const dispatch: any = useDispatch();
  const router = useRouter();

  const [triggerSnackbarFunc] = useTriggerSnackbar();

  const nationalities = constants.nationalities;

  const showNationalityDialog = useSelector(
    (state: any) => state.user?.showNationalityDialog
  );
  const user = useSelector((state: any) => state?.user?.user);
  const job = useSelector((state: any) => state.jobs?.job);
  const stateRegions = useSelector((state: any) => state.jobs?.stateRegions);

  const [isValidMobileNumber, setIsValidMobileNumber] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<string>(user?.mobileNumber);
  const [nationality, setNationality] = useState<string>(user?.nationality);
  const [currentLocation, setCurrentLocation] = useState<string>(
    user?.currentLocation
  );

  const viewHistoryState = useSelector(
    (state: any) => state.jobs.viewHistoryState
  );
  const jobListTab = useSelector((state: any) => state.jobs.jobListTab);
  const fypTab = Number(useSelector((state: any) => state.jobs.fypTab));

  const jobDiscovery = () => {
    if (origin === "individual-job-page") {
      return router.query.origin ?? "individual_job_page";
    }
    if (origin === "job-list") {
      return getJobDiscovery(jobListTab, viewHistoryState, fypTab);
    }
  };

  const [geoLocationStr, setGeoLocationStr] = useState<Country>(
    ((typeof process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "string" &&
      process.env.NEXT_PUBLIC_JSW_GEOLOCATION.toUpperCase()) ??
      "MY") as Country
  );

  const {
    applyJobFunc,
    checkRightToWorkAndRelocate,
    checkResumeRejected,
    checkScrapedJob,
  } = useJobApplication(jobOrigin, jobDiscovery);

  const currentCountry =
    process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my"
      ? "Malaysian"
      : process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "sg"
      ? "Singaporean"
      : "";

  function updateGeoLocation() {
    setGeoLocationStr(
      ((typeof process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "string" &&
        process.env.NEXT_PUBLIC_JSW_GEOLOCATION.toUpperCase()) ??
        "MY") as Country
    );
  }

  function handleClose() {
    updateGeoLocation();
    setMobileNumber("");

    const params = {
      showNationalityDialog: false,
    };

    dispatch(updateNationalityDialog(params));
  }

  function handleMobileNumber(mobileNumber) {
    setMobileNumber(mobileNumber);

    let mobileNumberValidatedString = String(mobileNumber);

    if (isPossiblePhoneNumber(mobileNumberValidatedString)) {
      setIsValidMobileNumber(true);
    } else {
      setIsValidMobileNumber(false);
    }
  }

  function handleNationality(event: any, nationality: string) {
    setNationality(nationality);
  }

  function handleLocation(event: any, currentLocation: any) {
    setCurrentLocation(currentLocation.state);
  }

  async function onSubmit() {
    if (isValidMobileNumber && nationality && mobileNumber && currentLocation) {
      sendTrackingEvent({
        event: "CE_click-next-nationality-popup",
      });

      const params = {
        mobileNumber: mobileNumber,
        nationality: nationality,
        currentLocation: currentLocation,
        email: user?.email,
        name: user?.name,
        freshGrad: user?.freshGrad,
      };

      setIsSaving(true);

      const response = await dispatch(
        updateUserMobileNumberAndNationality(params)
      );

      if (
        response.type === types.UPDATE_NATIONALITY_AND_MOBILE_NUMBER_SUCCESS
      ) {
        triggerSnackbarFunc({
          snackbarMessage:
            "Mobile number, nationality and location updated successfully",
          severity: "success",
        });
        handleClose();
        handleJobApply(job, response.payload);
      }
      setIsSaving(false);
    }
  }

  async function handleJobApply(job: any, user: any) {
    // CONDITION - RIGHT TO WORK OR RELOCATION PREFERENCE
    if (checkRightToWorkAndRelocate(job, user)) {
      return;
    }
    // CONDITION - NO RESUME / RESUME REJECTED
    // Do not send in application if user has no resume/rejected
    if (checkResumeRejected(job)) {
      sendTrackingEvent({
        event: "CE_job-application",
        "job-title": job?.title ?? "",
        resume: false,
        logged_in: true,
        "job-bucket-id": null,
        scraped: job?.category === "scraped",
      });
      return;
    }

    // CONDITION - SCRAPED JOB
    if (checkScrapedJob(job)) {
      sendTrackingEvent({
        event: "CE_job-application",
        resume: true,
        logged_in: true,
        scraped: true,
        "job-bucket-id": null, // no bucket id due to not calling applyJob API
        "job-title": job?.title,
      });
      return;
    }

    const params = {
      jobId: job.id,
      canCancel: false,
      origin: jobOrigin,
      jobDiscovery: jobDiscovery,
    };

    const response = await applyJobFunc(job, params);
    if (response.success) {
      sendTrackingEvent({
        event: "CE_job-application",
        resume: true,
        logged_in: true,
        scraped: false,
        "job-bucket-id": response?.payload?.jobBucketId,
        "job-title": response?.payload?.appliedJob?.title,
      });
    }
  }

  useEffect(() => {
    handleMobileNumber(user?.mobileNumber);
    setNationality(user?.nationality);
    setCurrentLocation(user?.currentLocation);
  }, [showNationalityDialog, user]);

  return (
    <DialogStyled open={showNationalityDialog}>
      <TitleContainer>
        <Title>
          You're just seconds away from making your job application!
        </Title>
        <IconButtonStyled onClick={handleClose}>
          <XMarkIconStyled />
        </IconButtonStyled>
      </TitleContainer>
      <SubtitleBox>
        <Subtitle>
          These mandatory information are missing from your user profile. Please
          fill them in to continue.
        </Subtitle>
      </SubtitleBox>
      <DividerStyled />

      <PhoneInputStyled
        placeholder="Mobile Number"
        defaultCountry={geoLocationStr}
        value={mobileNumber}
        onChange={handleMobileNumber}
        countryCallingCodeEditable={false}
        // international
        smartCaret={true}
        disabled={isSaving}
        $error={!isValidMobileNumber && mobileNumber !== "" ? "true" : "false"}
      />

      {!isValidMobileNumber && mobileNumber ? (
        <ErrorText>Invalid phone number</ErrorText>
      ) : null}
      <AutocompleteStyled
        id="nationality-select"
        options={nationalities}
        autoHighlight
        onChange={handleNationality}
        disabled={isSaving}
        value={nationality}
        renderInput={(params) => (
          <TextFieldStyled {...params} label="Nationality" />
        )}
      />
      <AutocompleteStyled
        id="location-select"
        options={stateRegions}
        autoHighlight
        onChange={handleLocation}
        disabled={isSaving}
        value={currentLocation}
        getOptionLabel={(option: OptionType | string) => {
          if (typeof option === "string") {
            return option;
          }

          if (option && option.state) {
            return option.state;
          }

          return "";
        }}
        isOptionEqualToValue={(option: OptionType, value) =>
          option.state === value
        }
        renderInput={(params) => (
          <TextFieldStyled {...params} label="Location" />
        )}
      />
      <DividerStyled />
      <ButtonBox>
        <SubmitButton
          disabled={
            isSaving || !mobileNumber || !nationality || !currentLocation
          }
          onClick={onSubmit}
          variant="contained"
        >
          next
        </SubmitButton>
      </ButtonBox>
    </DialogStyled>
  );
}

export default SharedNationalityDialog;
